// (C) Copyright 2020 MediaWink, LLC

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Input,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import ReactGoogleLogin from 'react-google-login';

import Spinner from './spinner/spinner';
import {
  setKeepSignedIn,
  triggerCreateAccountSaga,
  triggerLoginSaga,
} from '../actions/session';
import {
  OpenEyeIcon,
  ClosedEyeIcon,
} from '../constants/icons';
import ErrorDialog from './ErrorDialog';

const clientId = '615309811070-6e0qq3ucfdt5l6eqsm8ou8o1rpu52dph.apps.googleusercontent.com';
function SignIn() {
  // const [timerDone, setTimerDone] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createAccount = (payload, resolve, reject) => dispatch(
    triggerCreateAccountSaga(payload, resolve, reject),
  );
  const { promiseInProgress } = usePromiseTracker();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const loginState = useSelector((state) => state.undo.present.private.login);
  const lastmodtime = useSelector((state) => state.undo.present.private.lastmodtime);
  const setKeepSignedInAction = (signedIn) => dispatch(setKeepSignedIn(signedIn));
  const loginSaga = (payload, resolve, reject, nav) => dispatch(
    triggerLoginSaga(payload, resolve, reject, nav),
  );
  const [agree, setAgree] = useState(true);
  const [keepSignedInCheck, setKeepSignedInCheck] = useState(
    loginState.keepSignedIn === undefined || loginState.keepSignedIn,
  );
  const [showError, setShowError] = useState(0);
  const [email, setEmail] = useState('');
  let p;
  let p2;
  let retVal = false;
  const onLogIn = (data, createdAccount = false) => {
    console.log('onLogIn BEGIN **********************************', data, createdAccount);
    trackPromise(
      p = new Promise((resolve, reject) => {
        console.log('onLogIn promise');
        // if (p2 === 'NEVER') {
        retVal = loginSaga(data, resolve, reject, navigate);
        // }
        // throw new Error('TESTING'); // TEMP
      }),
    );
    p.catch((err) => {
      // Login failed.  Can we create a new account for them??
      console.log({ err });
      // TODO: Check reason for failure.  No space left, do NOT create new account.
      // TODO: Pass in checkAllowlist boolean flag
      // const allowlist = 'NOT_timnathwardzoom@gmail.com';
      // console.log('allowlist:', allowlist);

      if (!createdAccount) {
        // TODO: Gmail/Google create account
        // if so, let's do it!
        const accountInfo = {
          check_allowlist: true,
          account_email: data.email,
          account_token: data.account_token,
          account_first_name: data.account_first_name,
          account_full_name: data.account_full_name,
          account_password: data.account_password,
        };
        // trackCreateAccount(accountInfo);
        trackPromise(
          p2 = new Promise((resolve, reject) => {
            createAccount(accountInfo, resolve, reject);
            //         setMessage(`
            // Account created. Please send the following email:
            // `);
          }),
        );
        p2.then(() => {
          // Otherwise, let's show them a login failure
          // console.log('retVal', retVal);
          const status = retVal?.err?.response?.status;
          // console.log('status', status);
          setShowError(status || 1);
          onLogIn(data, true);
        });
        p2.catch((e) => {
          console.log('Account creation failed', e);
          setShowError(409); // Out of space on device??
        });
      } else {
        // console.log('SKIP create new account for NOT OK email');
        setShowError(1001);
      }
    });
    console.log('onLogIn END **********************************', data, createdAccount);
  };
  const onSuccess = (resp) => {
    console.log('onSuccess', resp);
    // console.log('BUT, do they have an AchieveHub account??');

    const data = {
      tokenId: resp?.tokenId,
      accessToken: resp.accessToken,
      pwd: values?.password,
      keepSignedIn: keepSignedInCheck,
      lastmodtime,

      account_token: resp.accessToken,
      // account_token: resp.tokenId,

      email: resp?.profileObj?.email,
      account_first_name: resp.profileObj.givenName,
      account_full_name: resp.profileObj.familyName,
      account_password: '1Global!Access!Password!', // accountPassword,

      // Only get item 1 and children
      item: 1,
      privateItem: 1,
      publicItem: 1,
    };
    console.log('DATA', data);
    // onLogIn(data);
    const createdAccount = false;
    console.log('onLogIn BEGIN **********************************', data, createdAccount);
    trackPromise(
      p = new Promise((resolve, reject) => {
        console.log('onLogIn promise');
        loginSaga(data, resolve, reject, navigate);
        // throw new Error('TESTING'); // TEMP
      }),
    );
    p.catch((err) => {
      // Login failed.  Can we create a new account for them??
      console.log({ err });
      // TODO: Check reason for failure.  No space left, do NOT create new account.
      // TODO: Pass in checkAllowlist boolean flag
      // const allowlist = 'NOT_timnathwardzoom@gmail.com';
      // console.log('allowlist:', allowlist);

      if (!createdAccount) {
        // TODO: Gmail/Google create account
        // if so, let's do it!
        const accountInfo = {
          check_allowlist: true,
          account_email: data.email,
          account_token: data.account_token,
          account_first_name: data.account_first_name,
          account_full_name: data.account_full_name,
          account_password: data.account_password,
        };
        // trackCreateAccount(accountInfo);
        trackPromise(
          p2 = new Promise((resolve, reject) => {
            createAccount(accountInfo, resolve, reject);
            //         setMessage(`
            // Account created. Please send the following email:
            // `);
          }),
        );
        p2.then(() => {
          // Otherwise, let's show them a login failure
          // console.log('retVal', retVal);
          const status = retVal?.err?.response?.status;
          // console.log('status', status);
          setShowError(status || 1);
          onLogIn(data, true);
        });
        p2.catch((e) => {
          console.log('Account creation failed', e);
          setShowError(409); // Out of space on device??
        });
      } else {
        // console.log('SKIP create new account for NOT OK email');
        setShowError(1001);
      }
    });
    console.log('onLogIn END **********************************', data, createdAccount);
  };
  const onFailure = (resp) => {
    console.log('onFailure', resp);
    console.log(' Not able to log in to gmail account.');
  };
  return (
    <>
      {promiseInProgress && (
        <Spinner
          text={`Loading...  If this takes longer than usual (10 seconds or so)
you may need to refresh your browser and try again...`}
        />
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const data = {
            tokenId: '',
            accessToken: '',
            email,
            pwd: values.password,
            keepSignedIn: keepSignedInCheck,
            lastmodtime,
          };
          onLogIn(data);
        }}
      >
        <Box display='flex' flexDirection='row' align='center' m={1}>
          <Checkbox
            checked={keepSignedInCheck}
            onChange={(event) => {
              setKeepSignedInCheck(event.target.checked);
              setKeepSignedInAction(event.target.checked);
            }}
          />
          <Box display='flex' flexDirection='row' align='center' m={1}>
            <Typography>
              Keep signed in (using
              <Link to='/cookies'>cookies</Link>
              )
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' align='center' m={1}>
          <Checkbox
            checked={agree}
            onChange={(event) => {
              setAgree(event.target.checked);
            }}
          />
          <Box display='flex' flexDirection='row' align='center' m={1}>
            <Typography>
              Agree to
              {' '}
              <Link to='/help'>Terms</Link>
              {' '}
              (updated Dec 3, 2021)
            </Typography>
          </Box>
        </Box>
        <Box align='center' m={1}>
          <ReactGoogleLogin
            clientId={clientId}
            buttonText='Sign in with Google'
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </Box>
        (May take a few seconds before the button is ready and enabled.)
        <Box align='center' m={1}>
          OR
        </Box>
        <TextField
          label='Email'
          variant='filled'
          required={true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
        />
        <InputLabel htmlFor='standard-adornment-password'>
          Password
        </InputLabel>
        <Input
          type={values.showPassword ? 'text' : 'password'}
          onChange={handlePasswordChange('password')}
          value={values.password}
          endAdornment={(
            <InputAdornment position='end'>
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <ClosedEyeIcon /> : <OpenEyeIcon />}
              </IconButton>
            </InputAdornment>
              )}
        />
        <Box m={1}>
          <Button
            color='primary'
            disabled={!agree}
            type='submit'
            variant='contained'
          >
            Sign In
          </Button>
        </Box>
        {showError > 0 && <ErrorDialog showError={showError} setShowError={setShowError} />}
      </form>
    </>
  );
}

export default SignIn;
