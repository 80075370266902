// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
// import { trackPromise } from 'react-promise-tracker';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
} from '@material-ui/core';
import ItemListWithAdd from './ItemListWithAdd';
import {
  VisibilityFilters,
} from '../actions/items';
import {
  selectPrivateItems,
  selectIsPrivate,
  selectPrivateBreadcrumbs,
  selectPublicItems,
  selectPublicBreadcrumbs,
  // selectMissingItems,
} from '../selectors/items';

const strMapToObj = (strMap) => {
  const obj = Object.create(null);
  const keys = Object.keys(strMap);
  for (let i = 0; i < strMap.length; i += 1) {
    const k = keys[i];
    const v = strMap[k];
    // We don’t escape the key '__proto__'
    // which can cause problems on older engines
    obj[k] = v;
  }
  return obj;
};

const getVisibleItems = (items, filter) => {
  // console.log('items', items);
  // console.log('Object.entries', Object.entries(items));
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return items;
    case VisibilityFilters.SHOW_COMPLETED:
      return strMapToObj(Object.entries(items).filter((t) => t[1].completed));
    case VisibilityFilters.SHOW_ACTIVE:
      return strMapToObj(Object.entries(items).filter((t) => !t[1].completed));
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};

function ItemListsTree({
  debug,
  hideAddBottom,
  hideAddTop,
  hideDelete,
  hideEdit,
  hideCheckmark,
}) {
  // console.log('ItemListsTree()');
  const isPrivate = useSelector(selectIsPrivate);
  // console.log({ isPrivate });
  const parentPrivate = useSelector(
    (state) => ((st) => selectPrivateItems(st)[
      state.undo.present.private.breadcrumbs.slice(-1)
    ])(state),
  );
  const parentPublic = useSelector(
    (state) => ((st) => selectPublicItems(st)[
      state.undo.present.public.breadcrumbs.slice(-1)
    ])(state),
  );

  // console.log('missingItems?');
  // const missingItems = useSelector(selectMissingItems);
  // console.log({ missingItems });
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  // console.log({ privateBreadcrumbs });
  const publicBreadcrumbs = useSelector(selectPublicBreadcrumbs);
  // console.log({ publicBreadcrumbs });

  // const selectedPrivateItems = useSelector(selectPrivateItems);
  // console.log({ selectedPrivateItems });

  // Object of items
  const privateItems = useSelector((state) => (privateBreadcrumbs.length < publicBreadcrumbs.length
    ? {} : getVisibleItems((
      !selectPrivateItems(state)[state.undo.present.private.breadcrumbs.slice(-1)]
        ? [] : selectPrivateItems(state)?.[
          state.undo.present.private.breadcrumbs.slice(-1)
        ].items || [])
      .map((i) => selectPrivateItems(state)[i])
      .reduce((acc, cur) => {
        if (cur) {
          acc[cur.id] = cur;
          // console.log('cur', cur);
        }
        return acc;
      }, {}), state.undo.present.private.visibilityFilter)));
  // console.log({ privateItems });

  // const selectedPublicItems = useSelector(selectPublicItems);
  // console.log({ selectedPublicItems });

  // Object of items
  const publicItems = useSelector((state) => (publicBreadcrumbs.length < privateBreadcrumbs.length
    ? {} : getVisibleItems((
      !selectPublicItems(state)[state.undo.present.public.breadcrumbs.slice(-1)]
        ? [] : selectPublicItems(state)?.[
          state.undo.present.public.breadcrumbs.slice(-1)
        ].items || [])
      .map((i) => {
        const r = selectPublicItems(state)[i];
        // console.log('i', i);
        // const pi = selectPublicItems(state);
        // console.log('publicItems', pi);
        // console.log('return', r);
        return r;
      })
      .reduce((acc, cur) => {
        // console.log('acc', acc, 'cur', cur);
        if (cur) {
          acc[cur.id] = cur;
          // console.log(' + acc', cur?.Name?.value, cur);
          // } else {
          // console.log(' - acc', acc);
        }
        return acc;
      }, {}), state.undo.present.public.visibilityFilter)));
  // console.log({ publicItems });
  const item = isPrivate ? parentPrivate : parentPublic;
  // console.log({ item });
  // if (!missingItems) {
  //   console.log('!missingItems!');
  //   return [];
  // }
  // console.log({ missingItems });
  // console.log('ItemListsTree() render');
  return (
    item
    && (
      <Box className='tour-list'>
        <ItemListWithAdd
          debug={debug}
          item={item}
          privateItems={privateItems}
          publicItems={publicItems}
          hideAddBottom={hideAddBottom}
          hideAddTop={hideAddTop}
          hideDelete={hideDelete}
          hideEdit={hideEdit}
          hideCheckmark={hideCheckmark}
        />
      </Box>
    )
  );
}

ItemListsTree.propTypes = {
  debug: PropTypes.bool.isRequired,
  hideAddBottom: PropTypes.bool,
  hideAddTop: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideCheckmark: PropTypes.bool,
};

ItemListsTree.defaultProps = {
  hideAddBottom: false,
  hideAddTop: false,
  hideDelete: false,
  hideEdit: false,
  hideCheckmark: false,
};

export default ItemListsTree;
