// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import {
  Box,
  TextField,
} from '@material-ui/core';

// import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';

import ListItemIcon from '@mui/material/ListItemIcon';
import {
  PrivateIcon,
  PublicIcon,
} from '../constants/icons';
import {
  selectItemsForBreadcrumbs,
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  selectBreadcrumbs,
  selectItems,
  selectIsPrivate,
  /*
  selectNextId,
  selectMaxId,
  selectUsedItemsCount,
  */
} from '../selectors';
import {
  triggerSetPrivateBreadcrumbsSaga,
  triggerSetPublicBreadcrumbsSaga,
} from '../actions/items';

function BreadcrumbsTails({
  debug,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const breadcrumbs = useSelector(selectBreadcrumbs);
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const publicBreadcrumbs = useSelector(selectPublicBreadcrumbs);
  const isPrivate = useSelector(selectIsPrivate);
  const items = useSelector(selectItemsForBreadcrumbs);
  /*
  const items = useSelector(
    state => state.undo.present.private.breadcrumbs.map(
      i => state.undo.present.private.entities.items[i],
    ),
  );
  */
  const allItems = useSelector(selectItems);
  /*
  const allItemsCount = useSelector(state => Object.keys(selectItems(state)).length);
  const maxId = useSelector(state => selectMaxId(state));
  const nextId = useSelector(state => selectNextId(state));
  const usedItemsCount = useSelector(state => selectUsedItemsCount(state));
*/

  const tail = breadcrumbs.length > 0 ? items[breadcrumbs.length - 1]
    && (items[breadcrumbs.length - 1] || undefined) : undefined;
  const parentItem = useSelector((state) => selectItems(state)[tail?.parent]);
  const setPrivateBreadcrumbs = (breadcrumbsIn, nav, navigateIn) => dispatch(
    triggerSetPrivateBreadcrumbsSaga({ breadcrumbs: breadcrumbsIn, nav, navigate: navigateIn }),
  );
  const setPublicBreadcrumbs = (breadcrumbsIn, nav, navigateIn) => dispatch(
    triggerSetPublicBreadcrumbsSaga({ breadcrumbs: breadcrumbsIn, nav, navigate: navigateIn }),
  );
  const handleChange = (/* event */) => {
    // console.log('event.target.value', event.target.value);
    // setAge(event.target.value);
  };

  const setBreadcrumbsTails = (i, nav, navigateIn) => {
    console.log({ 'setBreadcrumbsTails': i, privateBreadcrumbs });
    setPrivateBreadcrumbs([...privateBreadcrumbs.slice(0, -1), i], nav, navigateIn);
    setPublicBreadcrumbs([...publicBreadcrumbs.slice(0, -1), i], nav, navigateIn);
  };
  const itemsToSort = [...parentItem.items] || [];
  // console.log({ itemsToSort, allItems });
  return (
    <Box
      display='flex'
      flexDirection='row'
      style={{ flexWrap: 'wrap' }}
    >
      <FormControl sx={{ m: 1 }}>
        <TextField
          // labelId='demo-simple-select-autowidth-label'
          id='demo-simple-select-autowidth'
          value={`${allItems[tail.id]?.Name?.value}`}
          onChange={handleChange}
          // autoWidth={true}
          select={true}
        // label='Current Page'
        // displayEmpty={true}
        >
          {/* eslint-disable-next-line no-unsafe-optional-chaining */}
          {itemsToSort.sort((a, b) => (allItems[a]?.order || 0)
            - (allItems[b]?.order || 0))?.map((i) => (
              <MenuItem
                key={`${allItems[i]?.id}`}
                value={`${allItems[i]?.Name?.value}`}
                m={0}
                p={0}
                style={{
                  display: 'flex',
                  textTransform: 'none',
                  margin: 0,

                  fontSize: 15, // tail.id === i ? 25 : 15,
                  textDecorationLine: tail.id === i ? 'underline' : null,
                  // alignSelf: 'center',
                }}
                onClick={() => {
                  // setPrivateBreadcrumbs([...breadcrumbs.slice(0, -1), i]);
                  const item = allItems[i];
                  const name = item?.Name?.value;
                  const here = location.pathname;
                  const nav = `${here.match(/(.*)[/]/)[1] || ''}/${name}`;
                  setBreadcrumbsTails(i, nav, navigate);
                }}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  style={{ minWidth: 0 }}
                >
                  <ListItemIcon>
                    {isPrivate ? <PrivateIcon /> : <PublicIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      `${allItems[i]?.Name?.value}${debug === true ? ` {${allItems[i]?.id}}` : ''
                      }`
                    }
                  />
                </Box>
              </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Box>
  );
}

BreadcrumbsTails.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default BreadcrumbsTails;
